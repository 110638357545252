<template>
    <FullPageLoader :showLoader="showFullPageLoader" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">CAN</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"> 
               
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
        <!-- Info boxes -->
            <div class="row">
                <!-- <div class="col-md-12"><h3>Under Construction Till 5th October</h3></div>  -->
                <div class="col-md-12">
                <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">{{dataFrm.id > 0 ? 'Modify': 'Add'}}</h3>
                        </div>
                        <form method="post" @submit.prevent="submit()" enctype="multipart/form-data">
                            
                            <!-- <div v-for="(h, k) in dataFrm.HOLDER_RECORDS" :key="k">
                                

                                <input type="text" v-model="h.NAME" class="form-control">
                                {{h.NAME}}
                            </div> -->

                            <div class="card-body">
                                <div class="row" v-if="dataFrm.REQ_EVENT != 'CM'">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="label-bold">CAN REGISTRATION TYPE <span class="mandatory">&#42;</span></label>
                                            <div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="is_can_already_registered" id="is_can_already_registered_1" value="0" v-model="dataFrm.is_can_already_registered">
                                                    <label class="form-check-label" for="is_can_already_registered_1">
                                                        New CAN Creation
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" name="is_can_already_registered" id="is_can_already_registered_2" value="1" v-model="dataFrm.is_can_already_registered">
                                                    <label class="form-check-label" for="is_can_already_registered_2">
                                                        Existing CAN
                                                    </label>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="col-md-4" v-if="dataFrm.is_can_already_registered == 1">
                                        <div class="form-group" :class="{error: v$.existing_can_no.$error}">
                                            <label class="label-bold">COMMON ACCOUNT NUMBER (CAN) <span class="mandatory">&#42;</span></label>
                                            <input type="text" class="form-control form-control-sm" id="existing_can_no" name="existing_can_no" v-model="dataFrm.existing_can_no" placeholder="Enter CAN" maxlength="10" />
 
                                            <div v-if="v$.existing_can_no.$error" class="errortext">
                                                {{v$.existing_can_no.$errors[0].$message}}
                                            </div>
                                        
                                        </div> 
                                    </div>

                                    

                                </div>
                                <div class="row" v-if="dataFrm.REQ_EVENT == 'CM'">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="label-bold">CAN Registration Type <span class="mandatory">&#42;</span></label> 
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="REQ_EVENT_CR" name="REQ_EVENT" value="CR" v-model="dataFrm.REQ_EVENT" class="custom-control-input" required="" disabled="">
                                                        <label class="custom-control-label" for="REQ_EVENT_CR">New CAN creation</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="REQ_EVENT_CM" name="REQ_EVENT" value="CM" v-model="dataFrm.REQ_EVENT" class="custom-control-input" required="" disabled="">
                                                        <label class="custom-control-label" for="REQ_EVENT_CM">Modification of existing CAN</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>


                                    </div>
                                    <div class="col-sm-6" >
                                        <div class="form-group">
                                            <label class="label-bold">Common Account Number (CAN) <span class="mandatory">&#42;</span></label>
                                            <input type="text" class="form-control" id="CAN" name="CAN" v-model="dataFrm.CAN" placeholder="Enter CAN" maxlength="10" required="" minlength="10" disabled="" />
            
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="label-bold">EMAIL ADDRESS <span class="mandatory">&#42;</span></label>
                                            <div v-for="(em, k) in dataFrm.ENTITY_EMAIL_DETAILS" :key="k" :class="{error: v$.ENTITY_EMAIL_DETAILS.$each.$response.$errors[k].EMAIL_ID.length}">
                                                <input type="email" class="form-control form-control-sm" :id="`EMAIL_ID` + k" :name="`EMAIL_ID` + k" v-model="em.EMAIL_ID" placeholder="Enter Email Address" maxlength="255" />

                                                <div v-for="error in v$.ENTITY_EMAIL_DETAILS.$each.$response.$errors[k].EMAIL_ID" :key="error" class="errortext">
                                                    {{ error.$message }}
                                                </div>


                                            </div> 
                                            
                                        </div> 
                                    </div>
                                </div>    

                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <span class="headings">HOLDER DETAIL</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group" :class="{error: v$.HOLDING_TYPE.$error}">
                                            <label class="label-bold">HOLDING TYPE <span class="mandatory">&#42;</span></label>
                                            
                                            <select class="form-control form-control-sm" id="HOLDING_TYPE" name="HOLDING_TYPE" v-model="dataFrm.HOLDING_TYPE">
                                                <option value="">Select Holding Type</option>
                                                <option value="AS" v-if="!(dataFrm.INV_CATEGORY == 'M')">Anyone or Survivor</option>
                                                <option value="JO" v-if="!(dataFrm.INV_CATEGORY == 'M')">Joint</option>
                                                <option value="SI">Single</option>
                                            </select>

                                            <div v-if="v$.HOLDING_TYPE.$error" class="errortext">
                                                {{v$.HOLDING_TYPE.$errors[0].$message}}
                                            </div>
                                            

                                        </div> 
                                    </div>
                                    <div class="col-md-3">
                                         <div class="form-group" :class="{error: v$.INV_CATEGORY.$error}">
                                            <label class="label-bold">INVESTOR CATEGORY <span class="mandatory">&#42;</span></label>
                                            
                                            <select class="form-control form-control-sm" id="INV_CATEGORY" name="INV_CATEGORY" v-model="dataFrm.INV_CATEGORY">
                                                <option value="">Select Investor Category</option>
                                                <option value="I">Individual</option>
                                                <option value="M">Minor</option>
                                                <option value="S">Sole-proprietor</option>
                                                <option value="H" :disabled="dataFrm.is_can_already_registered == 0">HUF</option>
                                            </select> 

                                            <div v-if="v$.INV_CATEGORY.$error" class="errortext">
                                                {{v$.INV_CATEGORY.$errors[0].$message}}
                                            </div>
 
                                        </div> 
                                    </div>
                                    <div class="col-md-3">
                                         <div class="form-group" :class="{error: v$.TAX_STATUS.$error}">
                                            <label class="label-bold">RESIDENTIAL STATUS <span class="mandatory">&#42;</span></label>
                                            
                                            <select class="form-control  form-control-sm" id="TAX_STATUS" name="TAX_STATUS" v-model="dataFrm.TAX_STATUS">
                                                <option value="">Select Residential Status</option>
                                                <option value="RI" v-if="!(dataFrm.INV_CATEGORY == 'M')" class="">Resident Individual</option>
                                                <option value="RM">Resident Minor</option>
                                                <option value="RS" v-if="!(dataFrm.INV_CATEGORY == 'M')" class="">Sole Proprietor</option>
                                                <option value="NNI" v-if="!(dataFrm.INV_CATEGORY == 'M')" class="">Non Resident Individual - NRO</option>
                                                <option value="NNM">Non Resident Individual - NRO Minor</option>
                                                <option value="NPI" v-if="!(dataFrm.INV_CATEGORY == 'M')" class="">Person of Indian Origin</option>
                                                <option value="NPM">Person of Indian Origin - Minor</option>
                                                <option value="NRI" v-if="!(dataFrm.INV_CATEGORY == 'M')" class="">Non Resident Individual - NRE</option>
                                                <option value="NRM">Non Resident Individual - Minor NRE</option>
                                                <option value="PI" v-if="!(dataFrm.INV_CATEGORY == 'M')" class="">Foreign National</option>
                                                <option value="PM">Foreign National - Minor</option>

                                                <option value="PMO" v-if="!(dataFrm.INV_CATEGORY == 'M')">PIO (Minor) NRO</option>
                                                <option value="PME" v-if="!(dataFrm.INV_CATEGORY == 'M')">PIO (Minor) NRE</option>
                                                <option value="NPE">PIO (NRE)</option>
                                                <option value="NPO">PIO (NRO)</option>


                                            </select>

                                            <div v-if="v$.TAX_STATUS.$error" class="errortext">
                                                {{v$.TAX_STATUS.$errors[0].$message}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                         <div class="form-group">
                                            <label class="label-bold">NUMBER OF HOLDERS <span class="mandatory">&#42;</span></label>
                                            <select class="form-control form-control-sm" id="HOLDER_COUNT" name="HOLDER_COUNT" v-model="dataFrm.HOLDER_COUNT">
                                                <option value="1" :disabled="dataFrm.HOLDING_TYPE == 'AS' || dataFrm.HOLDING_TYPE == 'JO' || dataFrm.INV_CATEGORY == 'M'">1</option>
                                                <option value="2">2</option>
                                                <option value="3" :disabled="dataFrm.INV_CATEGORY == 'M'">3</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="nav customTab  nav-tabs">
                                            <li class="nav-item" v-for="(h, k) in dataFrm.HOLDER_RECORDS" :key="k">
                                                <a class="tab-holders nav-link" :class="{'active': k == selectedHolderTab}" href="" @click.prevent="selectedHolderTab = k">
                                                    <span v-if="h.HOLDER_TYPE != 'GU'">Holder #{{k+1}}</span>
                                                    <span v-if="h.HOLDER_TYPE == 'GU'">Guardian</span> 
                                                </a>
                                            </li> 
                                        </ul>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12" v-for="(h, k) in dataFrm.HOLDER_RECORDS" :key="k" v-show="k == selectedHolderTab"> <!-- -->
                                        
                                        <div class="card">
                                            <div class="card-header">
                                                BASIC DETAIL
                                            </div>
                                            <div class="card-body">
 
                                                 
                                                

                                                <!-- <input type="text" v-model="h.IDX"> -->
                                                <div class="row">
                                                    <div class="col-md-4" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                        <div class="form-group" :class="{error: v$.HOLDER_RECORDS.$each.$response.$errors[k].PAN_PEKRN_NO.length}">
                                                            <label class="label-bold">PAN NUMBER <span class="mandatory">&#42;</span></label>
                                                            <input type="text" class="form-control form-control-sm" :id="`holder_record_pan_pekrn_name`+k" :name="`holder_record_pan_pekrn_name`+k" v-model="h.PAN_PEKRN_NO" placeholder="Holder PAN or PEKRN Number" maxlength="10" @keydown="h.old_pan = h.PAN_PEKRN_NO" @keyup="h.PAN_PEKRN_NO.length == 10 && getPANDetail(h, k)" />

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$errors[k].PAN_PEKRN_NO" :key="error" class="errortext">
                                                                {{ error.$message }}
                                                            </div>
                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group" :class="{error: v$.HOLDER_RECORDS.$each.$response.$errors[k].HOLDER_TYPE.length}">
                                                            <label class="label-bold">HOLDING TYPE <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control  form-control-sm" :id="`HOLDER_TYPE`+k" :name="`HOLDER_TYPE`+k" v-model="h.HOLDER_TYPE">
                                                                <!-- <option value="" :disabled="h.is_guardian">Select Holding Type</option> -->
                                                                <option value="PR" v-if="k == 0">Primary</option>
                                                                <option value="SE" v-if="k == 1 && dataFrm.INV_CATEGORY != 'M'">Secondary</option>
                                                                <option value="TH" v-if="k == 2">Third</option>
                                                                <option value="GU" v-if="k == 1 && dataFrm.INV_CATEGORY == 'M'">Guardian</option>
                                                            </select>
                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$errors[k].HOLDER_TYPE" :key="error" class="errortext">
                                                                {{ error.$message }}
                                                            </div>
                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group" :class="{error: v$.HOLDER_RECORDS.$each.$response.$errors[k].NAME.length}">
                                                            <label class="label-bold">NAME <span class="mandatory">&#42;</span></label>
                                                            <input type="text" class="form-control form-control-sm" :id="`HOLDER_NAME`+k" :name="`HOLDER_NAME`+k" v-model="h.NAME" placeholder="Name" maxlength="105" />
                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$errors[k].NAME" :key="error" class="errortext">
                                                                {{ error.$message }}
                                                            </div>
                                                        
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group" :class="{error: v$.HOLDER_RECORDS.$each.$response.$errors[k].DOB.length}">
                                                            <label class="label-bold">DATE OF BIRTH <span class="mandatory">&#42;</span></label>
                                                            <input type="date" class="form-control form-control-sm" :id="`holder_record_DOB`+k" :name="`holder_record_DOB`+k" v-model="h.DOB" placeholder="DOB" />
                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$errors[k].DOB" :key="error" class="errortext">
                                                                {{ error.$message }}
                                                            </div>
                                                        </div> 
                                                    </div>
                                                 

                                                    <div class="col-md-4" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                        <div class="form-group">
                                                            <label class="label-bold">HOLDER PAN EXEMPT <span class="mandatory">&#42;</span></label>
                                                            <div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" :name="`holder_record_holder_PAN_exempt_flag`+k" :id="`holder_record_holder_PAN_exempt_flag1`+k" value="Y" v-model="h.PAN_EXEMPT_FLAG">
                                                                    <label class="form-check-label" :for="`holder_record_holder_PAN_exempt_flag1`+k">
                                                                        PEKRN
                                                                    </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" :name="`holder_record_holder_PAN_exempt_flag`+k" :id="`holder_record_holder_PAN_exempt_flag2`+k" value="N" v-model="h.PAN_EXEMPT_FLAG">
                                                                    <label class="form-check-label" :for="`holder_record_holder_PAN_exempt_flag2`+k">
                                                                        PAN
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                        <div class="form-group">
                                                            <label class="label-bold">AADHAAR NUMBER <span class="mandatory"></span></label>
                                                            <input type="text" class="form-control form-control-sm" :id="`holder_record_adharno`+k" :name="`holder_record_adharno`+k" v-model="h.AADHAAR_NO" placeholder="Aadhaar number" />
                                                        </div> 
                                                    </div>


                                                    <div class="col-md-4" v-if="h.HOLDER_TYPE == 'GU'" ng-cloak>
                                                        <div class="form-group">
                                                            <label class="label-bold" for="">Relation <span class="mandatory">&#42;</span></label>
                                                            <select class="custom-select" :name="`holder_record_relation`+k" :id="`holder_record_relation`+k" v-model="h.RELATIONSHIP">
                                                                <option value="">Select Relation</option>
                                                                <option value="01">Mother</option>
                                                                <option value="02">Father</option>
                                                                <option value="03">Court Appointed Legal Guardian</option>
                                                            </select>


                                                        </div>
                                                    </div>
                                                    <div class="col-md-4" v-if="h.HOLDER_TYPE == 'GU'" ng-cloak>
                                                        <div class="form-group">
                                                            <label class="label-bold">Proof <span class="mandatory">&#42;</span></label>
                                                            <select class="custom-select" :name="`holder_record_proof`+k" :id="`holder_record_proof`+k" v-model="h.REL_PROOF">
                                                                <option value="">Select Proof</option>
                                                                <option value="01">Birth Certificate</option>
                                                                <option value="02">Ration Card</option>
                                                                <option value="03">Passport</option>
                                                                <option value="04">PAN Card</option>
                                                                <option value="05">Court Order</option>
                                                            </select>

                                                        </div>
                                                    </div>
                                                     
                                                </div>

                                                <div class="row" v-if="dataFrm.is_can_already_registered == 0 && ( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                    <div class="col-md-12">
                                                        <code>* Please fill either residence or office phone number</code>
                                                    </div>
                                                </div>
 

                                                <div class="row" v-if="dataFrm.is_can_already_registered == 0 && ( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">RES. (ISD-STD-PHONE) <span class="mandatory">&#42;</span></label>
                                                            
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].RES_PHONE_NO.length}">
                                                                <input type="text" class="form-control form-control-sm input-code" :id="`holder_contact_res_ISD-Code`+k" :name="`holder_contact_res_ISD-Code`+k" v-model="h.CONTACT_DETAIL[0].RES_ISD" placeholder="ISD" maxlength="5">
                                                                <input type="text" class="form-control form-control-sm input-code" :id="`holder_contact_res_STD-Code`+k" :name="`holder_contact_res_STD-Code`+k" v-model="h.CONTACT_DETAIL[0].RES_STD" placeholder="STD" maxlength="5">
                                                                <input type="text" class="form-control form-control-sm" :id="`holder_contact_residence_phone_number`+k" :name="`holder_contact_residence_phone_number`+k" v-model="h.CONTACT_DETAIL[0].RES_PHONE_NO" placeholder="Residence Number" maxlength="15">
                                                            </div>
                                                             
                                                            
                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].RES_PHONE_NO" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">OFFICE (ISD-STD-PHONE)</label>
                                                            
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].OFF_PHONE_NO.length}">
                                                                <input type="text" class="form-control form-control-sm input-code" :id="`holder_contact_off_ISD-Code`+k" :name="`holder_contact_off_ISD-Code`+k" v-model="h.CONTACT_DETAIL[0].OFF_ISD" placeholder="ISD" maxlength="5">
                                                                <input type="text" class="form-control form-control-sm input-code" :id="`holder_contact_off_STD-Code`+k" :name="`holder_contact_off_STD-Code`+k" v-model="h.CONTACT_DETAIL[0].OFF_STD" placeholder="STD" maxlength="5">
                                                                <input type="text" class="form-control form-control-sm" :id="`holder_contact_off_phone_number`+k" :name="`holder_contact_off_phone_number`+k" v-model="h.CONTACT_DETAIL[0].OFF_PHONE_NO" placeholder="Office Number" maxlength="15">
                                                            </div> 
                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].OFF_PHONE_NO" :key="error" class="errortext">
                                                                
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                                
                                                            </div>

                                                        </div> 
                                                    </div>
                                                </div>

                                                <div class="row" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                    <div class="col-md-12">
                                                        <code>* Please fill either mobile or alternate mobile number</code>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">MOBILE (ISD-MOBILE) <span class="mandatory">&#42;</span></label> 
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_MOB_NO.length}">
                                                                <input type="text" class="form-control form-control-sm input-code" :id="`holder_contact_mobile_ISD-Code`+k" :name="`holder_contact_mobile_ISD-Code`+k" v-model="h.CONTACT_DETAIL[0].MOB_ISD_CODE" placeholder="ISD" maxlength="5"> 
                                                                <input type="text" class="form-control form-control-sm" :id="`holder_contact_mobile`+k" :name="`holder_contact_mobile`+k" v-model="h.CONTACT_DETAIL[0].PRI_MOB_NO" placeholder="Mobile Number" maxlength="15">
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_MOB_NO" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                        <div class="form-group">
                                                            <label class="label-bold">ALTERNATIVE MOBILE NUMBER</label>
                                                            
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].ALT_MOB_NO.length}"> 
                                                                <input type="text" class="form-control form-control-sm" :id="`holder_contact_amn`+k" :name="`holder_contact_amn`+k" v-model="h.CONTACT_DETAIL[0].ALT_MOB_NO" placeholder="Alternative Mobile Number" maxlength="15">
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].ALT_MOB_NO" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">  
                                                        <div class="form-group">
                                                            <label class="label-bold">Self Declaration Mobile Belongs To</label>
                                                            
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_MOB_BELONGSTO.length}"> 
                                                                
                                                                
                                                                <select class="form-control form-control-sm" :id="`holder_contact_sdm`+k" :name="`holder_contact_sdm`+k" v-model="h.CONTACT_DETAIL[0].PRI_MOB_BELONGSTO">
                                                                    <option value="">Self Declaration Mobile Belongs To</option>  
                                                                    
                                                                    <option value="DC" v-if="dataFrm.INV_CATEGORY != 'M'">Dependent Children</option>  
                                                                    <option value="DP" v-if="dataFrm.INV_CATEGORY != 'M'">Dependent Parents</option>  
                                                                    <option value="DS" v-if="dataFrm.INV_CATEGORY != 'M'">Dependent Siblings</option>  
                                                                    <option value="GD">Guardian</option>  
                                                                    <option value="SE" v-if="dataFrm.INV_CATEGORY != 'M'">Self</option>  
                                                                    <option value="SP" v-if="dataFrm.INV_CATEGORY != 'M'">Spouse</option>  
 
                                                                     
                                                                </select>
                                                            
                                                            
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_MOB_BELONGSTO" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>
                                                </div> 

                                                <div class="row" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                    <div class="col-md-12">
                                                        <code>* Please fill either primary or alternate email id</code>
                                                    </div>
                                                </div>

                                                <div class="row" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">PRIMARY EMAIL ID <span class="mandatory">&#42;</span></label>
                                                            
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_EMAIL.length}"> 
                                                                <input type="text" class="form-control form-control-sm" :id="`holder_contact_primaryemail`+k" :name="`holder_contact_primaryemail`+k" v-model="h.CONTACT_DETAIL[0].PRI_EMAIL" placeholder="Primary Email ID" maxlength="100">
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_EMAIL" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">ALTERNATIVE EMAIL ID</label>
                                                            
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].ALT_EMAIL.length}"> 
                                                                <input type="text" class="form-control form-control-sm" :id="`holder_contact_altemail`+k" :name="`holder_contact_altemail`+k" v-model="h.CONTACT_DETAIL[0].ALT_EMAIL" placeholder="Alternative Email ID" maxlength="100">
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].ALT_EMAIL" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>


                                                    <div class="col-md-4" v-if="( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                        <div class="form-group">
                                                            <label class="label-bold">Self Declaration Email Belongs To</label>
                                                            
                                                            <div class="input-group mb-3" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_EMAIL_BELONGSTO.length}"> 
                                                                
                                                                
                                                                <select class="form-control form-control-sm" :id="`holder_contact_sde`+k" :name="`holder_contact_sde`+k" v-model="h.CONTACT_DETAIL[0].PRI_EMAIL_BELONGSTO">
                                                                    <option value="">Self Declaration Email Belongs To</option>  
                                                                    
                                                                    <option value="DC" v-if="dataFrm.INV_CATEGORY != 'M'">Dependent Children</option>  
                                                                    <option value="DP" v-if="dataFrm.INV_CATEGORY != 'M'">Dependent Parents</option>  
                                                                    <option value="DS" v-if="dataFrm.INV_CATEGORY != 'M'">Dependent Siblings</option>  
                                                                    <option value="GD">Guardian</option>  
                                                                    <option value="SE" v-if="dataFrm.INV_CATEGORY != 'M'">Self</option>  
                                                                    <option value="SP" v-if="dataFrm.INV_CATEGORY != 'M'">Spouse</option>  
                                                                     
                                                                </select>
                                                            
                                                            
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].CONTACT_DETAIL.$each.$errors[0].PRI_EMAIL_BELONGSTO" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>


                                                </div> 
                                                


                                            </div>
                                            <div class="card-header" v-if="dataFrm.is_can_already_registered == 0 && ( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                ADDITIONAL KYC DETAILS
                                            </div>
                                            <div class="card-body" v-if="dataFrm.is_can_already_registered == 0 && ( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <code>* Please select gross annual income or fill net worth and net worth date</code>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">GROSS ANNUAL INCOME <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :name="`additional_kyc_gai`+k" :id="`additional_kyc_gai`+k" v-model="h.OTHER_DETAIL[0].GROSS_INCOME" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].GROSS_INCOME.length > 0}">
                                                                <option value="">Select Gross Income</option>
                                                                <option value="01">BELOW 1 LAC</option>
                                                                <option value="02">1-5 LAC</option>
                                                                <option value="03">5-10 LAC</option>
                                                                <option value="04">10-25 LAC</option>
                                                                <option value="05">25 Lacs to 1 Cr</option>
                                                                <option value="06">Greater than 1 Cr</option>
                                                            </select> 
                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].GROSS_INCOME" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group mb-3">
                                                            <label class="label-bold">NET WORTH</label>
                                                            
                                                            <div class="input-group" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].NET_WORTH.length > 0}"> 
                                                                <input type="text" class="form-control form-control-sm" :id="`additional_kyc_networth`+k" :name="`additional_kyc_networth`+k" v-model="h.OTHER_DETAIL[0].NET_WORTH" placeholder="Net Worth" maxlength="100">
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].NET_WORTH" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group mb-3">
                                                            <label class="label-bold">NET WORTH DATE</label>
                                                            
                                                            <div class="input-group" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].NET_DATE.length > 0}"> 
                                                                <input type="date" class="form-control form-control-sm" :id="`additional_kyc_networthdate`+k" :name="`additional_kyc_networthdate`+k" v-model="h.OTHER_DETAIL[0].NET_DATE" placeholder="Net Worth Date" >
                                                            </div>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].NET_DATE" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div> 
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">SOURCE OF WEALTH <span class="mandatory"></span></label>
                                                            
                                                            <select class="form-control form-control-sm" :name="`additional_kyc_sourceofwealth` + k" :id="`additional_kyc_sourceofwealth` + k" v-model="h.OTHER_DETAIL[0].SOURCE_OF_WEALTH" @change="h.OTHER_DETAIL[0].SOURCE_OF_WEALTH_OTH = ''">
                                                                <option value="">Select Source of Wealth</option>
                                                                <option value="01">Salary</option>
                                                                <option value="02">Bussiness Income</option>
                                                                <option value="03">Gift</option>
                                                                <option value="04">Ancestral Property</option>
                                                                <option value="05">Rental Income</option>
                                                                <option value="06">Prize Money</option>
                                                                <option value="07">Royalty</option>
                                                                <option value="08">Others</option>
                                                            </select>

                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4" v-if="h.OTHER_DETAIL[0].SOURCE_OF_WEALTH == '08'">
                                                        <div class="form-group">
                                                            <label class="label-bold">OTHER SOURCE OF WEALTH</label>
                                                            
                                                            <input type="text" class="form-control form-control-sm" :id="`additional_kyc_sourceofwealth_oth`+k" :name="`additional_kyc_sourceofwealth_oth`+k" v-model="h.OTHER_DETAIL[0].SOURCE_OF_WEALTH_OTH" maxlength="100" placeholder="Other source of wealth">

                                                        </div> 
                                                    </div>
                                                    
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">KRA ADDRESS TYPE <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :name="`additional_kyc_type_of_address_kra`+k" :id="`additional_kyc_type_of_address_kra` + k" v-model="h.OTHER_DETAIL[0].KRA_ADDR_TYPE" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].KRA_ADDR_TYPE.length > 0}">
                                                                <option value="">Select Type of Address given at KRA</option>
                                                                <option value="1">Residential or Business</option>
                                                                <option value="2">Residential</option>
                                                                <option value="3">Business</option>
                                                                <option value="4">Registered Office</option>
                                                            </select>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].KRA_ADDR_TYPE" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">OCCUPATION <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :name="`additional_kyc_occupaton`+k" :id="`additional_kyc_occupaton`+k" v-model="h.OTHER_DETAIL[0].OCCUPATION" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].OCCUPATION.length > 0}">
                                                                <option value="">Select Occupation</option>
                                                                <option value="01">Private Sector Service</option>
                                                                <option value="02">Public Sector</option>
                                                                <option value="03">Business</option>
                                                                <option value="04">Professional</option>
                                                                <option value="05">Agriculturist</option>
                                                                <option value="06">Retired</option>
                                                                <option value="07">Housewife</option>
                                                                <option value="08">Student</option>
                                                                <option value="09">Forex Dealer</option>
                                                                <option value="10">Government Service</option>
                                                                <option value="11">Doctor</option>
                                                                <option value="99">Others</option>
                                                            </select>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].OCCUPATION" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>

                                                        </div> 
                                                    </div> 
                                                    <div class="col-md-4" v-if="h.OTHER_DETAIL[0].OCCUPATION == 99">
                                                        <div class="form-group">
                                                            <label class="label-bold">OCCUPATION OTHER <span class="mandatory">&#42;</span></label>
                                                            <input type="text" class="form-control form-control-sm" :id="`additional_kyc_occupaton_other`+k" :name="`additional_kyc_occupaton_other`+k" v-model="h.OTHER_DETAIL[0].OCCUPATION_OTH" maxlength="50" placeholder="Occupation" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].OCCUPATION_OTH.length > 0}">

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].OCCUPATION_OTH" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        
                                                        
                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">PEP STATUS <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :id="`additional_kyc_pep_status`+k" :name="`additional_kyc_pep_status`+k" v-model="h.OTHER_DETAIL[0].PEP" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].PEP.length > 0}">
                                                                <option value="">Select PEP Status</option>
                                                                <option value="PEP">Politically Exposed Person</option>
                                                                <option value="RPEP">Related to Politically Exposed Person</option>
                                                                <option value="NA">Not Applicable</option>
                                                            </select>
                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].OTHER_DETAIL.$each.$errors[0].PEP" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">ANY OTHER INFORMATION</label>
                                                            
                                                            <input type="text" class="form-control form-control-sm" :id="`additional_kyc_any_other_information`+k" :name="`additional_kyc_any_other_information`+k" v-model="h.OTHER_DETAIL[0].ANY_OTH_INFO" maxlength="100" placeholder="Any Other Information">

                                                        </div> 
                                                    </div>



                                                </div>


                                            </div>
                                            <div class="card-header" v-if="dataFrm.is_can_already_registered == 0 && ( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                FATCA DETAILS
                                            </div>
                                            <div class="card-body" v-if="dataFrm.is_can_already_registered == 0 && ( (dataFrm.INV_CATEGORY == 'M' && k != 0) || dataFrm.INV_CATEGORY != 'M')">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="label-bold">TAX RESIDENCY IN A COUNTRY OTHER THAN INDIA? <span class="mandatory">&#42;</span></label>
                                                            <div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" :name="`holder_record_details_tax_res`+k" :id="`holder_record_details_tax_resYes`+k" value="Y" v-model="h.FATCA_DETAIL[0].TAX_RES_FLAG">
                                                                    <label class="form-check-label" :for="`holder_record_details_tax_resYes`+k">
                                                                        YES
                                                                    </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="radio" :name="`holder_record_details_tax_res`+k" :id="`holder_record_details_tax_resNo`+k" value="N" v-model="h.FATCA_DETAIL[0].TAX_RES_FLAG">
                                                                    <label class="form-check-label" :for="`holder_record_details_tax_resNo`+k">
                                                                        NO
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                 </div>

                                                 <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">PLACE OF BIRTH</label>
                                                            
                                                            <input type="text" class="form-control form-control-sm" :id="`holder_record_details_place_of_birth`+k" :name="`holder_record_details_place_of_birth`+k" v-model="h.FATCA_DETAIL[0].BIRTH_CITY" maxlength="60" placeholder="Place Of Birth">

                                                        </div> 
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">COUNTRY OF BIRTH <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :name="`holder_record_details_country_birth`+k" :id="`holder_record_details_country_birth`+k" v-model="h.FATCA_DETAIL[0].BIRTH_COUNTRY" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$errors[0].BIRTH_COUNTRY.length > 0}">
                                                                <option value="">Select Country of Birth</option>
                                                                <option :value="c.country_code" v-for="c in countries" :key="c.country_code">{{c.country_name}}</option>
                                                            </select>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$errors[0].BIRTH_COUNTRY" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                             
                                                        </div> 
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">COUNTRY OF CITIZENSHIP  <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :name="`holder_record_details_citizenship`+k" :id="`holder_record_details_citizenship`+k" v-model="h.FATCA_DETAIL[0].CITIZENSHIP" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$errors[0].CITIZENSHIP.length > 0}">
                                                                <option value="">Select Country of Citizenship</option>
                                                                <option :value="c.country_code" v-for="c in countries" :key="c.country_code">{{c.country_name}}</option>
                                                            
                                                            </select>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$errors[0].CITIZENSHIP" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                             
                                                        </div> 
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="label-bold">NATIONALITY  <span class="mandatory">&#42;</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :name="`holder_record_details_nationality`+k" :id="`holder_record_details_nationality`+k" v-model="h.FATCA_DETAIL[0].NATIONALITY" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$errors[0].NATIONALITY.length > 0}">
                                                                <option value="">Select Nationality</option>
                                                                <option :value="c.country_code" v-for="c in countries" :key="c.country_code">{{c.country_name}}</option>
                                                            
                                                            </select>

                                                            <div v-for="error in v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$errors[0].NATIONALITY" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                             
                                                        </div> 
                                                    </div>
                                                </div>

                                                <div class="row" v-if="h.FATCA_DETAIL[0].TAX_RES_FLAG == 'Y'">
                                                    <div class="col-md-12 mb-3">
                                                        <span class="headings">TAX DETAIL</span>
                                                    </div>
                                                </div>

                                                <div v-if="h.FATCA_DETAIL[0].TAX_RES_FLAG == 'Y'"> 

                                                    
                                                    <div class="row" v-for="(objTax, tk) in h.FATCA_DETAIL[0].TAXS_RECORDS" :key="tk">
                                                        <div class="col-md-4">

                                                            <!-- {{v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$data[0].TAXS_RECORDS.$each.$data[tk].TAX_COUNTRY.$error}} -->
                                                            <div class="form-group">
                                                                <label class="label-bold">COUNTRY <span class="mandatory" v-if="tk==0">&#42;</span></label>
                                                                
                                                                <select class="form-control form-control-sm" :name="`FATCA_tax_details_country` + k + `_`+tk" :id="`FATCA_tax_details_country` + k + `_` + tk" v-model="objTax.TAX_COUNTRY" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$data[0].TAXS_RECORDS.$each.$data[tk].TAX_COUNTRY.$error}">
                                                                    <option value="">Select Country</option>
                                                                    <option :value="c.country_code" v-for="c in countries" :key="c.country_code">{{c.country_name}}</option>
                                                            
                                                                </select>
 
                                                                
                                                                <div class="errortext" v-if="v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$data[0].TAXS_RECORDS.$each.$data[tk].TAX_COUNTRY.$error">
                                                                    Value is required
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label class="label-bold">REFERENCE NUMBER</label>
                                                                
                                                                <input type="text" class="form-control form-control-sm" :id="`FATCA_tax_reference_number` + k + `_` + tk" :name="`FATCA_tax_reference_number` + k + `_` + tk" v-model="objTax.TAX_REF_NO" maxlength="20" placeholder="Reference Number">

                                                            </div> 
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label class="label-bold">IDENTIFICATION TYPE <span class="mandatory" v-if="tk==0">&#42;</span></label>
                                                                <div v-if="objTax.IDENTI_TYPE != 'O'">
                                                                    <select class="form-control form-control-sm" :name="`FATCA_tax_identification_type`+k+`_`+tk" :id="`FATCA_tax_identification_type`+k+`_`+tk" v-model="objTax.IDENTI_TYPE" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$data[0].TAXS_RECORDS.$each.$data[tk].IDENTI_TYPE.$error}">
                                                                        <option value="">Select Identification Type</option>
                                                                        <option value="A">Passport</option>
                                                                        <option value="B">Election ID Card</option>
                                                                        <option value="C">PAN Card</option>
                                                                        <option value="D">ID Card</option>
                                                                        <option value="E">Driving License</option>
                                                                        <option value="G">UIDIA / Aadhar letter</option>
                                                                        <option value="H">NREGA Job Card</option>
                                                                        <option value="T">TIN</option>
                                                                        <option value="X">Not categorized</option>
                                                                        <option value="O">Others</option>
                                                                    </select>

                                                                    <div class="errortext" v-if="v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$data[0].TAXS_RECORDS.$each.$data[tk].IDENTI_TYPE.$error">
                                                                        Value is required
                                                                    </div>
                                                                </div>
                                                                <div v-if="objTax.IDENTI_TYPE == 'O'">
                                                                    <input type="text" class="form-control form-control-sm" :id="`FATCA_tax_identification_type_other`+k+`_`+tk" :name="`FATCA_tax_identification_type_other`+k+`_`+tk" v-model="objTax.IDENTI_TYPE_OTH" placeholder="Identification Type" :class="{error: v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$data[0].TAXS_RECORDS.$each.$data[tk].IDENTI_TYPE_OTH.$error}">

                                                                    <div class="errortext" v-if="v$.HOLDER_RECORDS.$each.$response.$data[k].FATCA_DETAIL.$each.$data[0].TAXS_RECORDS.$each.$data[tk].IDENTI_TYPE_OTH.$error">
                                                                        Value is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <hr />
                                <div class="row">
                                    <div class="col-md-12 mb-3">
                                        <span class="headings">BANK DETAILS</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="nav customTab  nav-tabs">
                                            <li class="nav-item" v-for="(b, k) in dataFrm.BANK_DETAILS" :key="k">
                                                <a class="tab-holders nav-link" :class="{'active': k == selectedBankTab}" href="" @click.prevent="selectedBankTab = k">
                                                    <span v-if="k == 0">Default Bank Account details</span>
                                                    <span v-if="k == 1">Second Bank Account</span>
                                                    <span v-if="k == 2">Third Bank Account</span>
                                                </a>
                                            </li> 
                                        </ul>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12" v-for="(b, k) in dataFrm.BANK_DETAILS" :key="k" v-show="k == selectedBankTab">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label :for="`bank_details_defaultbank_ac_no`+k">Bank A/C No. <span class="mandatory">*</span></label> 
                                                            <input type="text" class="form-control form-control-sm" :id="`bank_details_defaultbank_ac_no`+k" :name="`bank_details_defaultbank_ac_no` + k" v-model="b.ACCOUNT_NO" placeholder="Bank A/C No" :class="{error: v$.BANK_DETAILS.$each.$response.$errors[k].ACCOUNT_NO.length > 0}">
                                                        
                                                            <div v-for="error in v$.BANK_DETAILS.$each.$response.$errors[k].ACCOUNT_NO" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        </div> 
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="" :for="`bank_details_ac_type`+k">Account Type <span class="mandatory">*</span></label>
                                                            <select class="form-control form-control-sm" :id="`bank_details_ac_type` + k" :name="`bank_details_ac_type` + k" v-model="b.ACCOUNT_TYPE" :class="{error: v$.BANK_DETAILS.$each.$response.$errors[k].ACCOUNT_TYPE.length > 0}">
                                                                <option value="">--Select Account Type --</option>
                                                                <option value="SB">Savings Bank Account</option>
                                                                <option value="CA">Current Account</option>
                                                                <option value="CC">Cash Credit Account</option>
                                                                <option value="OD">Overdraft Account</option>
                                                                <option value="NRE">Non Resident External Account</option>
                                                                <option value="NRO">Non Resident Ordinary Account</option>
                                                                <option value="FCNR">Foreign Currency Non Resident Account</option>
                                                                <option value="NRSR">Special Non Resident Rupee Account</option>
                                                                <option value="OTH">Other type of Account</option> 
                                                            </select>

                                                            <div v-for="error in v$.BANK_DETAILS.$each.$response.$errors[k].ACCOUNT_TYPE" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label :for="`bank_details_bankid`+k">Bank <span class="mandatory">*</span></label>
                                                            
                                                            <select class="form-control form-control-sm" :id="`bank_details_bankid` +k" :name="`bank_details_bankid`+k" v-model="b.BANK_ID" :class="{error: v$.BANK_DETAILS.$each.$response.$errors[k].BANK_ID.length > 0}">
                                                                <option value="">--Select Bank --</option>
                                                                <option :value="b.bank_id" v-for="b in banks" :key="b.bank_id">{{b.bank_name}}</option>
                                                            
                                                            </select>
                                                            <div v-for="error in v$.BANK_DETAILS.$each.$response.$errors[k].BANK_ID" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label :for="`bank_details_micrcode`+k">MICR Code <span class="mandatory">*</span></label>
                                                            <input type="text" class="form-control form-control-sm" :id="`bank_details_micrcode`+k" :name="`bank_details_micrcode`+k" v-model="b.MICR_CODE" maxlength="9" placeholder="MICR Code" :class="{error: v$.BANK_DETAILS.$each.$response.$errors[k].MICR_CODE.length > 0}">
                                                        
                                                            <div v-for="error in v$.BANK_DETAILS.$each.$response.$errors[k].MICR_CODE" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label :for="`bank_details_ifsccode`+k">IFSC Code <span class="mandatory">*</span></label>
                                                            <input type="text" class="form-control form-control-sm" :id="`bank_details_ifsccode`+k" :name="`bank_details_ifsccode`+k" v-model="b.IFSC_CODE" maxlength="11" placeholder="IFSC Code" :class="{error: v$.BANK_DETAILS.$each.$response.$errors[k].IFSC_CODE.length > 0}">

                                                            <div v-for="error in v$.BANK_DETAILS.$each.$response.$errors[k].IFSC_CODE" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4" v-if="dataFrm.is_can_already_registered == 0">
                                                        <div class="form-group">
                                                            <label class="" :for="`bank_details_bank_proof`+k">Bank Proof <span class="mandatory">*</span></label>
                                                            <select class="form-control form-control-sm" :id="`bank_details_bank_proof`+k" :name="`bank_details_bank_proof`+k" v-model="b.PROOF" :class="{error: v$.BANK_DETAILS.$each.$response.$errors[k].PROOF.length > 0}" >
                                                                <option value="">Select Bank Proof</option>
                                                                <option value="14">Latest Bank Passbook</option>
                                                                <option value="15">Latest Bank Account Statement</option>
                                                                <option value="77">Cheque Copy</option>
                                                                <option value="78">Bank Letter</option>
                                                            </select>

                                                            <div v-for="error in v$.BANK_DETAILS.$each.$response.$errors[k].PROOF" :key="error" class="errortext">
                                                                <span v-if="!error.$message && error.$validator == 'required'">Value is required</span>
                                                                <span v-if="error.$message">
                                                                    {{ error.$message }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>



                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>

                                <div class="row" v-if="dataFrm.is_can_already_registered == 0">
                                    <div class="col-md-12"> 

                                        <div class="form-group"> 
                                            <h4>Nominee Option (Select 1) <span class="text-danger">*</span></h4>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="bank_details_intent_nominate_x" name="bank_details_intent_nominate" v-model="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG" value="X" class="custom-control-input">
                                                        <label class="custom-control-label text-uppercase" for="bank_details_intent_nominate_x">I/We do not wish to nominate under CAN <span style="text-transform: none;">(MFD will submit nomination individually to CAMS/KFintech <span class="text-danger">(new folio subscription cannot be initiated from MFU)</span>)</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="bank_details_intent_nominate_Yes" name="bank_details_intent_nominate" v-model="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG" value="N" class="custom-control-input">
                                                        <label class="custom-control-label text-uppercase" for="bank_details_intent_nominate_Yes">I/We do not wish to Nominate <span style="text-transform: lowercase;">(will override the existing nominations under mapped folios)</span><!-- Yes --></label>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-12">
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="bank_details_intent_nominate_no" name="bank_details_intent_nominate" v-model="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG" value="Y" class="custom-control-input">
                                                        <label class="custom-control-label text-uppercase" for="bank_details_intent_nominate_no">I intend to nominate <span style="text-transform: lowercase;">(will override the existing nominations under mapped folios)</span><!-- No --></label>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div> 
                                    </div>

                                    <div class="col-md-12 mb-3" v-if="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y' || dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'N'">
                                        <h4>Nominee Verifications Method (Select 1) <span class="text-danger">*</span></h4>
                                      
                                        <div class="row">
                                            <div class="col-auto">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="nom_verify_type_Yes" name="nom_verify_type" v-model="dataFrm.NOMINEE_DETAILS[0].NOM_VERIFY_TYPE" value="P" class="custom-control-input">
                                                    <label class="custom-control-label text-uppercase" for="nom_verify_type_Yes">Physical Form (Upload)</label>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="nom_verify_type_no" name="nom_verify_type" v-model="dataFrm.NOMINEE_DETAILS[0].NOM_VERIFY_TYPE" value="E" class="custom-control-input">
                                                    <label class="custom-control-label text-uppercase" for="nom_verify_type_no">2FA</label>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="margin-top: 15px;">
                                                <div class="alert alert-info" v-if="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'N' && dataFrm.NOMINEE_DETAILS[0].NOM_VERIFY_TYPE == 'P'">CAN holder(s) need to fill, sign and upload the <a href="https://www.mfuindia.com/downloads/Nomination-opt-Out.pdf" target="_blank">Nominee Opt-Out Form</a> / <a href="https://www.mfuindia.com/downloads/Nomination-opt-out-fillable.pdf" target="_blank">Nominee Opt-Out Fillable Form</a></div> 
                                                <div class="alert alert-info" v-if="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y' && dataFrm.NOMINEE_DETAILS[0].NOM_VERIFY_TYPE == 'P'">CAN holder(s) need to fill, sign and upload the <a href="https://www.mfuindia.com/downloads/Nomination-Change.pdf" target="_blank">Nominee Form</a> / <a href="https://www.mfuindia.com/downloads/Nomination-Change-fillable.pdf" target="_blank">Nominee Fillable Form</a></div>
                                            </div> 
                                        </div> 

                                    </div>

                                    
                                    <div class="col-md-12 mb-3" v-if="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y'">
                                        <span class="headings">NOMINEE DETAILS</span>
                                    </div> 

                                    <div class="col-md-12" v-if="dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y'">
                                        <table class="table table-bordered">
                                            <tr>
                                                <td>Name <span class="mandatory">*</span></td>
                                                <td>Relation</td>
                                                <td>Percentage  <span class="mandatory">*</span></td>
                                                <td>Date of birth</td>
                                                <td>Guardian Name</td>
                                                <td>Guardian Relationship</td>
                                                <td>Guardian DOB</td>
                                            </tr>
                                            <tr v-for="(objNominee, k) in dataFrm.NOMINEE_DETAILS[0].NOMINEES_RECORDS" :key="k">
                                                <td>
                                                    <input type="text" class="form-control form-control-sm" :id="`nominee_record_name`+k" :name="`nominee_record_name`+k" v-model="objNominee.NOMINEE_NAME" maxlength="105" placeholder="Name" :class="{error: v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOMINEE_NAME.$error}">

                                                    <div class="errortext" v-if="v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOMINEE_NAME.$error" >
                                                        Value is required
                                                    </div>
 
                                                </td>
                                                <td>
                                                    <!-- <input type="text" class="form-control form-control-sm" :id="`nominee_record_relation`+k" :name="`nominee_record_relation`+k" v-model="objNominee.RELATION" maxlength="20" placeholder="Relation"> -->
                                                    
                                                    <select class="form-control form-control-sm" :id="`nominee_record_relation`+k" :name="`nominee_record_relation`+k" v-model="objNominee.RELATION">
                                                        <option value="">--Select Relation--</option>  
                                                            <option value="MFU01">FATHER</option>
                                                            <option value="MFU02">MOTHER</option>
                                                            <option value="MFU03">COURT APPOINTED LEGAL GUARDIAN</option>
                                                            <option value="MFU04">AUNT</option>
                                                            <option value="MFU05"> BROTHER-IN-LAW</option>
                                                            <option value="MFU06">BROTHER</option>
                                                            <option value="MFU07">DAUGHTER</option>
                                                            <option value="MFU08">DAUGHTER-IN-LAW</option>
                                                            <option value="MFU09">FATHER-IN-LAW</option>
                                                            <option value="MFU10">GRAND DAUGHTER</option>
                                                            <option value="MFU11">GRAND FATHER</option>
                                                            <option value="MFU12">GRAND MOTHER</option>
                                                            <option value="MFU13">GRAND SON</option>
                                                            <option value="MFU14">MOTHER-IN-LAW</option>
                                                            <option value="MFU15">NEPHEW</option>
                                                            <option value="MFU16">NIECE</option>
                                                            <option value="MFU17">SISTER</option>
                                                            <option value="MFU18">SISTER-IN-LAW</option>
                                                            <option value="MFU19">SON</option>
                                                            <option value="MFU20">SON-IN-LAW</option>
                                                            <option value="MFU21">SPOUSE</option>
                                                            <option value="MFU22">UNCLE</option>
                                                            <option value="MFU23">OTHERS</option>
                                                    </select>  

                                                    <div class="errortext" v-if="v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].RELATION.$error" >
                                                        Value is required
                                                    </div>
                                                
                                                </td>
                                                <td>
                                                    <input type="number" class="form-control form-control-sm" :id="`nominee_record_percentage` + k" :name="`nominee_record_percentage`+k" v-model="objNominee.PERCENTAGE" maxlength="3" placeholder="Percentage" :class="{error: v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].PERCENTAGE.$error}">

                                                    <div class="errortext" v-if="v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].PERCENTAGE.$error" >
                                                        Value is required
                                                    </div>
                                                </td>
                                                <td>
                                                    <input :id="`nominee_record_dob`+k" class="form-control form-control-sm" :name="`nominee_record_dob`+k" v-model="objNominee.DOB" placeholder="Date of birth" type="date">
                                                </td>
                                                <td>
                                                    <div v-if="getYearDiff(objNominee.DOB)">
                                                        <input type="text" class="form-control form-control-sm" :id="`nominee_record_guardian_name`+k" :name="`nominee_record_guardian_name`+k" v-model="objNominee.NOM_GURI_NAME" placeholder="Nominee Guardian Name" maxlength="105" :class="{error: v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOM_GURI_NAME.$error}">

                                                        <div class="errortext" v-if="v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOM_GURI_NAME.$error" >
                                                            Value is required
                                                        </div>
                                                    </div>
 
                                                </td> 
                                                <td>
                                                    <div v-if="getYearDiff(objNominee.DOB)">
                                                        <!-- <input type="text" class="form-control form-control-sm" :id="`nominee_record_guardian_relationship`+k" :name="`nominee_record_guardian_relationship`+k" v-model="objNominee.NOM_GURI_REL" placeholder="Nominee Guardian Relationship" maxlength="20" :class="{error: v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOM_GURI_REL.$error}"> -->

                                                        <select class="form-control form-control-sm" :id="`nominee_record_guardian_relationship`+k" :name="`nominee_record_guardian_relationship`+k" v-model="objNominee.NOM_GURI_REL" placeholder="Nominee Guardian Relationship" maxlength="20" :class="{error: v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOM_GURI_REL.$error}">
                                                            <option value="">--Select--</option>  
                                                            <option value="MFU24">FATHER</option>
                                                            <option value="MFU25">MOTHER</option>
                                                            <option value="MFU26">COURT APPOINTED LEGAL GUARDIAN</option>
                                                            <option value="MFU27">AUNT</option>
                                                            <option value="MFU28">BROTHER-IN-LAW</option>
                                                            <option value="MFU29">BROTHER</option>
                                                            <option value="MFU30">DAUGHTER</option>
                                                            <option value="MFU31">DAUGHTER-IN-LAW</option>
                                                            <option value="MFU32">FATHER-IN-LAW</option>
                                                            <option value="MFU33">GRAND DAUGHTER</option>
                                                            <option value="MFU34">GRAND FATHER</option>
                                                            <option value="MFU35">GRAND MOTHER</option>
                                                            <option value="MFU36">GRAND SON</option>
                                                            <option value="MFU37">MOTHER-IN-LAW</option>
                                                            <option value="MFU38">NEPHEW</option>
                                                            <option value="MFU39">NIECE</option>
                                                            <option value="MFU40">SISTER</option>
                                                            <option value="MFU41">SISTER-IN-LAW</option>
                                                            <option value="MFU42">SON</option>
                                                            <option value="MFU43">SON-IN-LAW</option>
                                                            <option value="MFU44">SPOUSE</option>
                                                            <option value="MFU45">UNCLE</option>
                                                            <option value="MFU46">OTHERS</option>
                                                        </select> 

                                                        <div class="errortext" v-if="v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOM_GURI_REL.$error" >
                                                            Value is required
                                                        </div>
                                                    </div>
 
                                                </td> 
                                                <td>
                                                    <div v-if="getYearDiff(objNominee.DOB)">
                                                        <input type="date" class="form-control form-control-sm" :id="`nominee_record_guardian_dob`+k" :name="`nominee_record_guardian_dob`+k" v-model="objNominee.NOM_GURI_DOB" placeholder="Nominee Guardian DOB" maxlength="105" :class="{error: v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOM_GURI_DOB.$error}">

                                                        <div class="errortext" v-if="v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k] && v$.NOMINEE_DETAILS.$each.$response.$errors[0].NOMINEES_RECORDS[k].$response.$data[k].NOM_GURI_DOB.$error" >
                                                            Value is required
                                                        </div>
                                                    </div>
 
                                                </td> 
                                            </tr>
                                        </table>
                                    </div>

                                </div>



                                <div class="row" v-if="dataFrm.is_can_already_registered == 0">
                                    <div class="col-md-12 mb-3">
                                        <span class="headings">UPLOAD PROOF</span>
                                    </div>

                                    <div class="col-md-12">
                                        <table class="table table-bordered">
                                            <tr>
                                                <td>S.No.</td>
                                                <td>Proof For</td>
                                                <td>Upload</td>
                                                <td class="text-center">Action</td>
                                            </tr>
                                            <tr v-for="(p, k) in dataFrm.proofUploadArray" :key="k">
                                                <td>{{k+1}}</td>
                                                <td>
                                                    <select class="form-control form-control-sm" v-model="p.mfu_code" :disabled="p.id">
                                                        <option value="">--Select Proof For--</option>
                                                        <option value="1#PC">Copy of PAN of 1st Holder</option>
                                                        <option value="1#PC">Copy of PAN of 2nd Holder</option>
                                                        <option value="1#PC">Copy of PAN of 3rd Holder</option>

                                                        <option value="2#BP">Proof of Bank Account #1</option>
                                                        <option value="2#BP">Proof of Bank Account #2</option>
                                                        <option value="2#BP">Proof of Bank Account #3</option>

                                                        <option value="3#BC">Minor Birth Certificate proof</option>
                                                        <option value="4#FA">Document proof related to other</option>

                                                        <option value="8#NF">Nominee Form / Nominee Opt-Out Form</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <div v-if="!p.file_url">
                                                        <input type="file" @change="readImageUrl($event, p)">
    
                                                        <div class="mt-2" v-if="p.imagepath"><img :src="p.imagepath" alt="" class="img-thumbnail" width="100"></div>
                                                        
                                                        <!-- <DropZone 
                                                            :maxFiles="Number(1)"
                                                            :url="uploadConfig.url"
                                                            :uploadOnDrop="true"
                                                            :multipleUpload="false"
                                                            :parallelUpload="1"
                                                            :headers="uploadConfig.headers"
                                                            @uploaded="proofUploaded($event,p)"
                                                            @removedFile="onFileRemove($event,p)"
                                                            
                                                            /> -->
                                                        
    
                                                        <br><code>Allowed Format: jpg, gif, bmp, png</code>
                                                    </div>  
                                                    <div v-if="p.file_url">
                                                        <img :src="p.file_url" class="img-thubnail" width="100" /> 
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <a title="Delete Proof" @click.prevent="deleteProof(p, k)" class="btn btn-info btn-circle btn-circle-sm m-1"><i class="fas fa-trash"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <button type="button" class="btn btn-primary btn-sm" @click="addMoreProof()">Add More</button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>


                            </div>

                            <div class="card-body">

                            </div>

                            <div class="card-footer">
                                <button type="button" class="btn btn-success btn-sm mr-3" @click="saveForLater()">Save For Later</button>
                                <button class="btn btn-primary btn-sm">Submit</button>
                            </div>
 

                        </form>

                    </div>
                </div>
            </div>
    
    
        </div>
    </section>
 

</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import useVuelidate from "@vuelidate/core";
import { required, numeric, requiredIf, minValue, maxValue, helpers, email} from '@vuelidate/validators'
import { ref, reactive, watch, onMounted, computed, onBeforeMount } from 'vue'
import EventService from '@/services/EventService.js'
import moment from 'moment'
// import { DropZone } from 'dropzone-vue';
import { useRouter, useRoute } from 'vue-router'
import Swal from 'sweetalert2'

export default {
    name: 'AddECan',
    components: {
        // DropZone,
    },
    setup() {
 
        const router = useRouter()
        const route = useRoute() 

        const showFullPageLoader = ref(false)
        const selectedHolderTab = ref(0)
        const selectedBankTab = ref(0)
        const countries = ref([])
        const banks = ref([])
        const uploadConfig = EventService.getMfuFileUploadConfig() 
 
        function getCountries() {
            EventService.getPostData("post", "mfu/getAllCountry", {})
            .then(response => {
                // console.log(response.data)                   
                countries.value = response.data
                
            })
            .catch(error => {  
                // console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        function getBanks() { 
            EventService.getPostData("post", "mfu/getAllBanks", {})
            .then(response => {
                // console.log(response.data)                   
                banks.value = response.data
                
            })
            .catch(error => {  
                // console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        getCountries()
        getBanks()
         

        const holderObj = {
            IDX: '',
            old_pan: '',
            PAN_PEKRN_NO: '',
            HOLDER_TYPE: '',
            NAME: '',
            DOB: '',
            PAN_EXEMPT_FLAG: 'N',
            AADHAAR_NO: '',
            RELATIONSHIP: '',
            REL_PROOF: '',
            
            CONTACT_DETAIL: [{
                RES_ISD: '',
                RES_STD: '',
                RES_PHONE_NO: '',
                OFF_ISD: '',
                OFF_STD: '',
                OFF_PHONE_NO: '',
                MOB_ISD_CODE: '',
                PRI_MOB_NO: '',
                ALT_MOB_NO: '',
                PRI_MOB_BELONGSTO: '',
                PRI_EMAIL: '',
                ALT_EMAIL: '',
                PRI_EMAIL_BELONGSTO: ''
            }],
            OTHER_DETAIL: [{
                GROSS_INCOME: '',
                NET_WORTH: '',
                NET_DATE: '',
                SOURCE_OF_WEALTH: '',
                SOURCE_OF_WEALTH_OTH: '',
                KRA_ADDR_TYPE: '',
                OCCUPATION: '',
                OCCUPATION_OTH: '',
                PEP: '',
                ANY_OTH_INFO: ''
            }],
            FATCA_DETAIL: [{
                TAX_RES_FLAG: 'N',
                BIRTH_CITY: '',
                BIRTH_COUNTRY: '',
                CITIZENSHIP: '',
                NATIONALITY: '',
                TAXS_RECORDS: [
                    {
                        IDX: 0,
                        TAX_COUNTRY: '',
                        TAX_REF_NO: '',
                        IDENTI_TYPE: '',
                        IDENTI_TYPE_OTH: ''
                    },
                    {
                        IDX: 1,
                        TAX_COUNTRY: '',
                        TAX_REF_NO: '',
                        IDENTI_TYPE: '',
                        IDENTI_TYPE_OTH: ''
                    },
                    {
                        IDX: 2,
                        TAX_COUNTRY: '',
                        TAX_REF_NO: '',
                        IDENTI_TYPE: '',
                        IDENTI_TYPE_OTH: ''
                    },
                    {
                        IDX: 3,
                        TAX_COUNTRY: '',
                        TAX_REF_NO: '',
                        IDENTI_TYPE: '',
                        IDENTI_TYPE_OTH: ''
                    }
                ]
            }]
        }

        const proofObj = { mfu_code: '' }

        let dataFrm = reactive({
            id: 0,
            is_can_already_registered: 0,
            CAN: '',
            REQ_EVENT: 'CR',
            REG_TYPE: 'E',
            PROOF_UPLOAD_BY_CAN: 'Y',
            ENABLE_ONLINE_ACCESS_FLAG: 'Y',
            existing_can_no: '',
            ENTITY_EMAIL_DETAILS: [{ EMAIL_ID: '' }],
            HOLDING_TYPE: '',
            INV_CATEGORY: '',
            TAX_STATUS: '',
            HOLDER_COUNT: 1,
            HOLDER_RECORDS: [ 
            ],
            BANK_DETAILS: [
                {
                    SEQ_NUM: 1,
                    ACCOUNT_NO: '',
                    ACCOUNT_TYPE: '',
                    BANK_ID: '',
                    MICR_CODE: '',
                    IFSC_CODE: '',
                    PROOF: ''
                },
                {
                    SEQ_NUM: 2,
                    ACCOUNT_NO: '',
                    ACCOUNT_TYPE: '',
                    BANK_ID: '',
                    MICR_CODE: '',
                    IFSC_CODE: '',
                    PROOF: ''
                },
                {
                    SEQ_NUM: 3,
                    ACCOUNT_NO: '',
                    ACCOUNT_TYPE: '',
                    BANK_ID: '',
                    MICR_CODE: '',
                    IFSC_CODE: '',
                    PROOF: ''
                }
            ],
            NOMINEE_DETAILS: [{
                NOMIN_OPT_FLAG: 'N',
                NOM_VERIFY_TYPE: '',
                NOMINEES_RECORDS: [
                    {
                        IDX: 0,
                        NOMINEE_NAME: '',
                        RELATION: '',
                        PERCENTAGE: '',
                        DOB: '',
                        NOM_GURI_NAME: '',
                        NOM_GURI_REL: '',
                        NOM_GURI_DOB: ''
                    },
                    {
                        IDX: 1,
                        NOMINEE_NAME: '',
                        RELATION: '',
                        PERCENTAGE: '',
                        DOB: '',
                        NOM_GURI_NAME: '',
                        NOM_GURI_REL: '',
                        NOM_GURI_DOB: ''
                    },
                    {
                        IDX: 2,
                        NOMINEE_NAME: '',
                        RELATION: '',
                        PERCENTAGE: '',
                        DOB: '',
                        NOM_GURI_NAME: '',
                        NOM_GURI_REL: '',
                        NOM_GURI_DOB: ''
                    }
                ], 
            }],
            proofUploadArray: [
                     
            ]

        })

        

        // submit()

        function addMoreProof() {
            // console.log(dataFrm.proofUploadArray)
            dataFrm.proofUploadArray.push(Object.assign({}, proofObj))
        }
        
        watch(() => dataFrm.HOLDER_COUNT, (currentValue, oldValue) => {
            // console.log(currentValue)      
            // console.log(oldValue) 

            if(currentValue > oldValue) {
                let diff = currentValue - oldValue

                if(diff == 1) {
                    let HTYPE = ''
                    if(dataFrm.INV_CATEGORY == 'M') {
                        HTYPE = 'GU'
                    } else {
                        HTYPE = 'SE'
                    }

                    let c1 = Object.assign({}, holderObj, { HOLDER_TYPE: HTYPE, IDX: 1 })
                    let c2 = JSON.parse(JSON.stringify(c1));
                    dataFrm.HOLDER_RECORDS.push(c2)
                } else if(diff == 2) {

                    let c1 = Object.assign({}, holderObj, { HOLDER_TYPE: 'SE', IDX: 1 })
                    let c2 = JSON.parse(JSON.stringify(c1));
                    dataFrm.HOLDER_RECORDS.push(c2)

                    let c3 = Object.assign({}, holderObj, { HOLDER_TYPE: 'TH', IDX: 1 })
                    let c4 = JSON.parse(JSON.stringify(c3));
                    dataFrm.HOLDER_RECORDS.push(c4)

                    
                    // dataFrm.HOLDER_RECORDS.push(Object.assign({}, holderObj, { HOLDER_TYPE: 'SE', IDX: 1 }))
                    // dataFrm.HOLDER_RECORDS.push(Object.assign({}, holderObj, { HOLDER_TYPE: 'TH', IDX: 2 }))
                }

                selectedHolderTab.value = 0
            } else {
                let diff = oldValue - currentValue
                if(diff == 1) {
                    dataFrm.HOLDER_RECORDS.pop()
                } else if(diff == 2) {
                    dataFrm.HOLDER_RECORDS.pop()
                    dataFrm.HOLDER_RECORDS.pop()
                }

                selectedHolderTab.value = 0
            }
            
        })

        function getYearDiff(dt) {
            // console.log(dt)
            if(dt) {
                var startDate = moment(dt, "YYYY-MM-DD");
                var endDate = moment();

                console.log(endDate.diff(startDate, 'year'))
 
                return endDate.diff(startDate, 'year') < 18; 
            } else {
                return false
            }
        }
 

        watch(() => dataFrm.HOLDING_TYPE, (currentValue, oldValue) => {
            if(currentValue == 'SI' && dataFrm.INV_CATEGORY == 'M') {
                dataFrm.HOLDER_COUNT = 2
            } else if(currentValue == "AS" || currentValue == 'JO') {
                dataFrm.HOLDER_COUNT = 2
            } else {
                // dataFrm.HOLDER_COUNT = 1
            }
        })



        watch(() => dataFrm.INV_CATEGORY, (currentValue, oldValue) => {
            if(currentValue == 'M') {
                dataFrm.HOLDING_TYPE = 'SI' 
                // dataFrm.TAX_STATUS = '' 
                dataFrm.HOLDER_COUNT = 2 
                if(dataFrm.HOLDER_RECORDS.length > 1) {
                    dataFrm.HOLDER_RECORDS[1].HOLDER_TYPE = 'GU'
                }
            } else {
                if(oldValue == 'M') {
                    dataFrm.HOLDER_RECORDS[1].HOLDER_TYPE = 'SE'
                }
            }
        })

        watch(() => dataFrm.is_can_already_registered, (currentValue, oldValue) => {
            if(currentValue == 0) { 
                if(dataFrm.proofUploadArray.length == 0) {
                    dataFrm.proofUploadArray.push(Object.assign({}, proofObj, {}))
                }
            }
        })

         

        const validationRules = computed(() => {
            const rules = {
                ENTITY_EMAIL_DETAILS: { 
                    $each: helpers.forEach({
                        EMAIL_ID: {
                            required, email
                        }
                    })
                },
                existing_can_no: { 
                    requiredIfFoo: requiredIf(dataFrm.is_can_already_registered == 1) 
                },
                HOLDING_TYPE: { required }, 
                INV_CATEGORY: { required },
                TAX_STATUS: { required },
                HOLDER_RECORDS: {
                    $each: helpers.forEach({
                        // PAN_PEKRN_NO: { 
                        //     requiredIfFoo: requiredIf(dataFrm.INV_CATEGORY != 'M')     
                        // },
                        NAME: { required },
                        DOB: { required },
                        HOLDER_TYPE: { required },
                        CONTACT_DETAIL: { 
                            $each: helpers.forEach({ 
                                RES_PHONE_NO: {  
                                    required: (value, obj, parentProp) => { 
                                        // console.log(parentProp)
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }


                                        // console.log(value, obj) 
                                        if(obj.RES_PHONE_NO == '' && obj.OFF_PHONE_NO == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    } 
                                },
                                OFF_PHONE_NO: { 
                                    required: (value, obj, parentProp) => {

                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        // console.log(value, obj) 
                                        if(obj.RES_PHONE_NO == '' && obj.OFF_PHONE_NO == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }, 
                                },
                                PRI_MOB_NO: { 
                                    required: (value, obj, parentProp) => {
                                        
                                        // const customerIndex = dataFrm.HOLDER_RECORDS.findIndex(c => c.IDX === parentProp.IDX)
                             
                                        // if(parentProp.IDX == parseInt(1)) { 
                                        //     return false
                                        // } else { 
                                        //     return true
                                        // }

                                        if(obj.PRI_MOB_NO == '' && obj.ALT_MOB_NO == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }, 
                                },
                                ALT_MOB_NO: { 
                                    required: (value, obj, parentProp) => {
                                        // console.log(value, obj) 
                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        if(obj.PRI_MOB_NO == '' && obj.ALT_MOB_NO == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }, 
                                },
                                PRI_MOB_BELONGSTO: {
                                    required: (value, obj, parentProp) => {
                                        // console.log(value, obj) 
                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        if(obj.PRI_MOB_BELONGSTO == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }, 
                                },
                                PRI_EMAIL: { 
                                    required: (value, obj, parentProp) => { 

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }
 
                                        if(obj.PRI_EMAIL == '' && obj.ALT_EMAIL == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    },  
 
                                },
                                ALT_EMAIL: { 
                                    required: (value, obj, parentProp) => {
                                        // console.log(value, obj) 
                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        if(obj.PRI_EMAIL == '' && obj.ALT_EMAIL == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }, 
                                },
                                PRI_EMAIL_BELONGSTO: {
                                    required: (value, obj, parentProp) => {
                                        // console.log(value, obj) 
                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        if(obj.PRI_EMAIL_BELONGSTO == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }, 
                                }


                            })
                            
                           
                        },
                        OTHER_DETAIL: {
                            $each: helpers.forEach({ 
                                GROSS_INCOME: {
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        // console.log(value, obj) 
                                        if(obj.GROSS_INCOME == '' && obj.NET_DATE == '' && obj.NET_WORTH == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    },
                                },
                                NET_WORTH: {
                                    required: (value, obj, parentProp) => {

                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        // console.log(value, obj) 
                                        if(obj.GROSS_INCOME == '' && obj.NET_WORTH == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    },
                                },
                                NET_DATE: {
                                    required: (value, obj, parentProp) => {

                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        // console.log(value, obj) 
                                        if(obj.NET_DATE == '' && obj.NET_WORTH != '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    },
                                },
                                KRA_ADDR_TYPE: { 
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }
 
                                        if(value == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }    
                                },
                                OCCUPATION: { 
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }
 
                                        if(value == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }   

                                },
                                OCCUPATION_OTH: {
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }

                                        // console.log(value, obj) 
                                        if(obj.OCCUPATION == '99' && obj.OCCUPATION_OTH == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    },
                                },
                                PEP: { 
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }
 
                                        if(value == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }       

                                }
                            })
                        },
                        FATCA_DETAIL: {
                            $each: helpers.forEach({ 
                                BIRTH_COUNTRY: { 
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }
 
                                        if(value == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }       
                                },
                                CITIZENSHIP: { 
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }
 
                                        if(value == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }       
                                },
                                NATIONALITY: { 
                                    required: (value, obj, parentProp) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                            return true
                                        }
 
                                        if(value == '') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }   

                                },
                                TAXS_RECORDS: {
                                    $each: helpers.forEach({ 
                                        TAX_COUNTRY: {
                                            required: (value, obj, myparentProp, parentProp) => {
                                                if(dataFrm.is_can_already_registered == 1) {
                                                    return true
                                                }

                                                if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                                    return true
                                                }
                                                
                                                if(obj.IDX == 0 && obj.TAX_COUNTRY == '' && myparentProp.TAX_RES_FLAG == 'Y') {
                                                    return false
                                                } else {
                                                    return true
                                                } 
                                            } 
                                        },
                                        IDENTI_TYPE: {
                                            required: (value, obj, myparentProp, parentProp) => {

                                                if(dataFrm.is_can_already_registered == 1) {
                                                    return true
                                                }

                                                if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                                    return true
                                                }
                                                // console.log(value, obj)  
                                                if(obj.IDX == 0 && obj.IDENTI_TYPE == '' && myparentProp.TAX_RES_FLAG == 'Y') {
                                                    return false
                                                } else {
                                                    return true
                                                } 
                                            }  
                                        },
                                        IDENTI_TYPE_OTH: {
                                            required: (value, obj, myparentProp, parentProp) => {

                                                if(dataFrm.is_can_already_registered == 1) {
                                                    return true
                                                }

                                                if(dataFrm.INV_CATEGORY == 'M' && parentProp.IDX == 0) {
                                                    return true
                                                }
                                                // console.log(value, obj)  
                                                if(obj.IDX == 0 && obj.IDENTI_TYPE == 'O' && obj.IDENTI_TYPE_OTH == '' && myparentProp.TAX_RES_FLAG == 'Y') {
                                                    return false
                                                } else {
                                                    return true
                                                } 
                                            }  
                                        }
                                    })
                                }
                            })
                        }
                        
                    })
                },
                BANK_DETAILS: {
                    $each: helpers.forEach({
                        ACCOUNT_NO: { 
                            required: (value, obj) => {
                                // console.log(value, obj)  
                                if(obj.SEQ_NUM == 1 && value == '') {
                                    return false
                                } else {
                                    return true
                                } 
                            }
                        },
                        ACCOUNT_TYPE: { 
                            required: (value, obj) => {
                                // console.log(value, obj)  
                                if(obj.SEQ_NUM == 1 && value == '') {
                                    return false
                                } else {
                                    return true
                                } 
                            }
                        },
                        BANK_ID: { 
                            required: (value, obj) => {
                                // console.log(value, obj)  
                                if(obj.SEQ_NUM == 1 && value == '') {
                                    return false
                                } else {
                                    return true
                                } 
                            }
                        },
                        MICR_CODE: { 
                            required: (value, obj) => {
                                // console.log(value, obj)  
                                if(obj.SEQ_NUM == 1 && value == '') {
                                    return false
                                } else {
                                    return true
                                } 
                            }
                        },
                        IFSC_CODE: { 
                            required: (value, obj) => {
                                // console.log("---", value, obj)  
                                // console.log(value == '')
                                if(obj.SEQ_NUM == 1 && obj.IFSC_CODE == '') { 
                                    return false
                                } else {
                                    return true
                                } 
                            }    
                        },
                        PROOF: { 
                            required: (value, obj) => {
                                if(dataFrm.is_can_already_registered == 1) {
                                    return true
                                }
                                // console.log(value, obj)  
                                if(obj.SEQ_NUM == 1 && value == '') {
                                    return false
                                } else {
                                    return true
                                } 
                            }    
                        }
                    })
                },
                NOMINEE_DETAILS: {
                    $each: helpers.forEach({
                        NOMINEES_RECORDS: {
                            $each: helpers.forEach({
                                NOMINEE_NAME: {
                                    required: (value, obj) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }
                                        // console.log(value, obj)  
                                        if(obj.IDX == 0 && value == '' && dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }
                                },
                                RELATION: {
                                    required: (value, obj) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }
                                        // console.log(value, obj)  
                                        if(obj.IDX == 0 && value == '' && dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }
                                },
                                PERCENTAGE: {
                                    required: (value, obj) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }

                                        // console.log(value, obj)  
                                        if(obj.IDX == 0 && value == '' && dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y') {
                                            return false
                                        } else {
                                            return true
                                        } 
                                    }
                                },
                                NOM_GURI_NAME: {
                                    required: (value, obj) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }
 
                                        if(obj.IDX == 0 && value == '' && (obj.DOB && getYearDiff(obj.DOB)) && dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y') {
                                            return false
                                        } else {
                                            return true
                                        } 
 
                                    }
                                },
                                NOM_GURI_REL: {
                                    required: (value, obj) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }
 
                                        if(obj.IDX == 0 && value == '' && (obj.DOB && getYearDiff(obj.DOB)) && dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y') {
                                            return false
                                        } else {
                                            return true
                                        } 
 
                                    }
                                },
                                NOM_GURI_DOB: {
                                    required: (value, obj) => {
                                        if(dataFrm.is_can_already_registered == 1) {
                                            return true
                                        }
 
                                        if(obj.IDX == 0 && value == '' && (obj.DOB && getYearDiff(obj.DOB)) && dataFrm.NOMINEE_DETAILS[0].NOMIN_OPT_FLAG == 'Y') {
                                            return false
                                        } else {
                                            return true
                                        } 
 
                                    }
                                }
                            })
                        }
                    })
                }
                
            }


 
 
            

            return rules
        }) 


 

        const v$ = useVuelidate(validationRules, dataFrm)

        function submit() { 
            this.v$.$validate()
            console.log(dataFrm)
            console.log(this.v$)

            if(this.v$.$error) {
                notify({
                    title: "Error",
                    text: 'Please fill all mandatory fields with (*) mark',
                    type: 'error'
                }); 
                return false
            } 

            showFullPageLoader.value = true

            let canid = route.params.id ?? 0

            let dataToSend = Object.assign({}, dataFrm, {stype: '', id: canid}) 
            EventService.getPostData("post", "mfu/saveECAN", dataToSend)
            .then(response => {
                showFullPageLoader.value = false
                // console.log(response.data)    
                if(response.data.success == 0) {
                    let errormsg = ''
                    response.data.errors.forEach(function(v, k){
                        errormsg = errormsg + (k+1) + ") " + v + "<br />"
                    })

                    notify({
                        title: "Error",
                        text: errormsg,
                        type: 'error'
                    });
                } else {
                    showFullPageLoader.value = false
                    response.data.errors.forEach(function(v, k){
                        notify({
                            title: "Success",
                            text: v,
                            type: 'success'
                        });
                    })

                    location.href = "#/list-ecan/1"
                }
 
                
            })
            .catch(error => {  
                showFullPageLoader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
            
            
        } 

        function saveForLater() {  
            showFullPageLoader.value = true
            // console.log(dataFrm)
            let canid = route.params.id ?? 0

            let dataToSend = Object.assign({}, dataFrm, {stype: 'SAVEFORLATER', id: canid}) 
            EventService.getPostData("post", "mfu/saveECAN", dataToSend)
            .then(response => {
                showFullPageLoader.value = false
                // console.log(response.data)    
                if(response.data.success == 0) {
                    let errormsg = ''
                    response.data.errors.forEach(function(v, k){
                        errormsg = errormsg + (k+1) + ") " + v + "<br />"
                    })

                    // notify({
                    //     title: "Error",
                    //     text: errormsg,
                    //     type: 'error'
                    // });

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        html: errormsg, 
                    })

                } else {
                    showFullPageLoader.value = false
                    response.data.successmessages.forEach(function(v, k){
                        notify({
                            title: "Success",
                            text: v,
                            type: 'success'
                        });
                    })

                    location.href = "#/list-ecan/1"
                }
 
                
            })
            .catch(error => {  
                showFullPageLoader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }


        function getPANDetail(obj, hidx) {

            console.log(obj.old_pan, obj.PAN_PEKRN_NO)
            if(obj.old_pan == obj.PAN_PEKRN_NO) {
                return false;
            }
            
            EventService.getPostData("post", "mfu/getPANDetailJson", {p: obj.PAN_PEKRN_NO})
            .then(response => {
                // console.log(response.data.DATA['BANK_DETAIL.ACCOUNT_NO'])   
                 
                const keys = Object.keys(response.data.DATA); 
                // console.log("tt",keys.length)

                if(keys.length > 0) {
                    obj.NAME = '';
                    obj.DOB = '';
                    obj.AADHAAR_NO = '';
                    obj.CONTACT_DETAIL[0].RES_ISD = '';
                    obj.CONTACT_DETAIL[0].RES_STD = '';
                    obj.CONTACT_DETAIL[0].RES_PHONE_NO = '';

                    obj.CONTACT_DETAIL[0].OFF_ISD = '';
                    obj.CONTACT_DETAIL[0].OFF_STD = '';
                    obj.CONTACT_DETAIL[0].OFF_PHONE_NO = '';

                    obj.CONTACT_DETAIL[0].MOB_ISD_CODE = '';
                    obj.CONTACT_DETAIL[0].PRI_MOB_NO = '';
                    obj.CONTACT_DETAIL[0].ALT_MOB_NO = '';

                    obj.CONTACT_DETAIL[0].PRI_MOB_BELONGSTO = '';
                    obj.CONTACT_DETAIL[0].PRI_EMAIL_BELONGSTO = '';

                    obj.CONTACT_DETAIL[0].PRI_EMAIL = '';
                    obj.CONTACT_DETAIL[0].ALT_EMAIL = '';

                    obj.OTHER_DETAIL[0].GROSS_INCOME = '';
                    obj.OTHER_DETAIL[0].NET_WORTH = '';
                    obj.OTHER_DETAIL[0].NET_DATE = '';
                    obj.OTHER_DETAIL[0].SOURCE_OF_WEALTH = '';
                    obj.OTHER_DETAIL[0].SOURCE_OF_WEALTH_OTH = '';
                    obj.OTHER_DETAIL[0].KRA_ADDR_TYPE = '';
                    obj.OTHER_DETAIL[0].OCCUPATION = '';
                    obj.OTHER_DETAIL[0].PEP = '';
                    obj.OTHER_DETAIL[0].ANY_OTH_INFO = '';

                    obj.FATCA_DETAIL[0].BIRTH_CITY = '';
                    obj.FATCA_DETAIL[0].BIRTH_COUNTRY = '';

                    obj.FATCA_DETAIL[0].CITIZENSHIP = '';
                    obj.FATCA_DETAIL[0].NATIONALITY = '';

                    obj.FATCA_DETAIL[0].TAXS_RECORDS[0].TAX_COUNTRY = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[0].TAX_REF_NO = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[0].IDENTI_TYPE = '';

                    obj.FATCA_DETAIL[0].TAXS_RECORDS[1].TAX_COUNTRY = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[1].TAX_REF_NO = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[1].IDENTI_TYPE = '';

                    obj.FATCA_DETAIL[0].TAXS_RECORDS[2].TAX_COUNTRY = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[2].TAX_REF_NO = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[2].IDENTI_TYPE = '';

                    obj.FATCA_DETAIL[0].TAXS_RECORDS[3].TAX_COUNTRY = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[3].TAX_REF_NO = '';
                    obj.FATCA_DETAIL[0].TAXS_RECORDS[3].IDENTI_TYPE = '';

                    if(hidx == 0 || (dataFrm.INV_CATEGORY == 'M' && hidx == 1)) {
                        dataFrm.BANK_DETAILS[0].ACCOUNT_NO = '';
                        dataFrm.BANK_DETAILS[0].ACCOUNT_TYPE = "";
                        dataFrm.BANK_DETAILS[0].BANK_ID = "";
                        dataFrm.BANK_DETAILS[0].MICR_CODE = "";
                        dataFrm.BANK_DETAILS[0].IFSC_CODE = "";
                        dataFrm.BANK_DETAILS[0].PROOF = "";
                    }
                    keys.forEach((key, index) => {
                        // console.log(`${key}: ${response.data.DATA[key]}`);
                        let r = response.data.DATA[key]
                        if((/^[a-zA-Z_0-9]+[\.{1}][a-zA-Z_0-9]+$/g).test(key)) {
                            var split_k = key.split(".");
                            if(split_k[0] == "CONTACT_DETAIL" || split_k[0] == "OTHER_DETAIL" || split_k[0] == "FATCA_DETAIL") {
                                if(!obj[split_k[0]][0][split_k[1]] || obj[split_k[0]][0][split_k[1]] == "") {
                                    obj[split_k[0]][0][split_k[1]] = r;
                                }
                            } else if(split_k[0] == "BANK_DETAIL" && (hidx == 0 || (dataFrm.INV_CATEGORY == 'M' && hidx == 1))) {
                                if(!dataFrm.BANK_DETAILS[0][split_k[1]] || dataFrm.BANK_DETAILS[0][split_k[1]] == ''){
                                    dataFrm.BANK_DETAILS[0][split_k[1]] = r; 
                                }
                            }
                        } else {
                            if(key == 'DOB') {
                                // console.log(k + "=" + r);
                                // r = $filter('date')(r, 'dd-MM-yyyy');
                            } else if(key == 'AADHAAR_NO') {
                                if(r == "INVALID" || r == 'DECLINED') {
                                    r = '';
                                }
                            }

                            if(!obj[key] || obj[key] == '') {
                                obj[key] = r;
                            }
                        }

                    });
                }
                

              
            })
            .catch(error => {  
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })

            
        }

        // function proofUploaded(f, p) {
        //     console.log(f[0],p)
        // }

        // function onFileRemove(f, p) {
        //     console.log(f,p)
        // }

        function readImageUrl(input, p) {
            // console.log(input, p)
            const image = input.target.files[0];
            // console.log(image.name)
            let reader = new FileReader()
            reader.onload = (event) => {
                p.imagepath = event.target.result
                p.file_name = image.name
                // console.log(event.target)
            }

            reader.readAsDataURL(image)
        }

        function getECanDetail() {
            // alert(route.params.id)
            showFullPageLoader.value = true

            EventService.getPostData("post", "mfu/modify-ecan", {id: route.params.id})
            .then(response => {
                console.log(response.data)    
                const keys = Object.keys(response.data.reg_request); 
                console.log(keys)

                let reg_request = response.data.reg_request
                let basic_details = response.data.basic_details
                let proof = response.data.proof

                dataFrm["REQ_EVENT"] = reg_request['REQ_EVENT']
                dataFrm["CAN"] = reg_request['CAN']
                dataFrm["is_can_already_registered"] = reg_request['is_can_already_registered']
                 
                 
                if(parseInt(basic_details.can_submitted) == parseInt(0) && basic_details.can_no == "") {
                    dataFrm['REQ_EVENT'] = "CR"
                } else {
                    if(parseInt(dataFrm['is_can_already_registered']) == parseInt(1)) {
                        dataFrm['REQ_EVENT'] = "CR"	
                        dataFrm['existing_can_no'] = basic_details.can_no;
                    } else {
                        dataFrm['REQ_EVENT'] = "CM"	
                        dataFrm['CAN'] = basic_details.can_no;
                    }
                }

                
                
                dataFrm["REG_TYPE"] = reg_request['REG_TYPE']
                dataFrm["PROOF_UPLOAD_BY_CAN"] = reg_request['PROOF_UPLOAD_BY_CAN']
                dataFrm["ENABLE_ONLINE_ACCESS_FLAG"] = reg_request['ENABLE_ONLINE_ACCESS_FLAG']
                dataFrm["PROOF_UPLOAD_LINK"] = reg_request['PROOF_UPLOAD_LINK']
                dataFrm["HOLDING_TYPE"] = reg_request['HOLDING_TYPE']
                dataFrm["INV_CATEGORY"] = reg_request['INV_CATEGORY']
                dataFrm["TAX_STATUS"] = reg_request['TAX_STATUS']
                dataFrm["HOLDER_COUNT"] = reg_request['HOLDER_COUNT']
                
                // alert(dataFrm["HOLDER_COUNT"])
                

                dataFrm["ENTITY_EMAIL_DETAILS"][0]['EMAIL_ID'] = reg_request['ENTITY_EMAIL_DETAILS'][0]['EMAIL_ID'][0]

                setTimeout(() => {
                    reg_request['HOLDER_RECORDS'][0]['HOLDER_RECORD'].forEach((v, k) => {
                        // console.log(v)
                        // console.log("xxx",dataFrm["HOLDER_RECORDS"][k])
                        if(v['AADHAAR_NO']) {
                            dataFrm["HOLDER_RECORDS"][k]['AADHAAR_NO'] = v['AADHAAR_NO']
                        } else {
                            dataFrm["HOLDER_RECORDS"][k]['AADHAAR_NO'] = ""
                        }
                        
                        dataFrm["HOLDER_RECORDS"][k]['DOB'] = v['DOB']
                        dataFrm["HOLDER_RECORDS"][k]['HOLDER_TYPE'] = v['HOLDER_TYPE']
                        dataFrm["HOLDER_RECORDS"][k]['NAME'] = v['NAME']
                        dataFrm["HOLDER_RECORDS"][k]['PAN_EXEMPT_FLAG'] = v['PAN_EXEMPT_FLAG']
                        dataFrm["HOLDER_RECORDS"][k]['PAN_PEKRN_NO'] = v['PAN_PEKRN_NO']
                        dataFrm["HOLDER_RECORDS"][k]['old_pan'] = v['PAN_PEKRN_NO']
                        dataFrm["HOLDER_RECORDS"][k]['RELATIONSHIP'] = v['RELATIONSHIP']
                        dataFrm["HOLDER_RECORDS"][k]['REL_PROOF'] = v['REL_PROOF']

                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['ALT_EMAIL'] = v['CONTACT_DETAIL'][0]['ALT_EMAIL']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['ALT_MOB_NO'] = v['CONTACT_DETAIL'][0]['ALT_MOB_NO']

                        
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['PRI_MOB_BELONGSTO'] = v['CONTACT_DETAIL'][0]['PRI_MOB_BELONGSTO']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['PRI_EMAIL_BELONGSTO'] = v['CONTACT_DETAIL'][0]['PRI_EMAIL_BELONGSTO']

                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['MOB_ISD_CODE'] = v['CONTACT_DETAIL'][0]['MOB_ISD_CODE']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['OFF_ISD'] = v['CONTACT_DETAIL'][0]['OFF_ISD']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['OFF_PHONE_NO'] = v['CONTACT_DETAIL'][0]['OFF_PHONE_NO']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['OFF_STD'] = v['CONTACT_DETAIL'][0]['OFF_STD']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['PRI_EMAIL'] = v['CONTACT_DETAIL'][0]['PRI_EMAIL']

                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['PRI_MOB_NO'] = v['CONTACT_DETAIL'][0]['PRI_MOB_NO']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['RES_ISD'] = v['CONTACT_DETAIL'][0]['RES_ISD']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['RES_PHONE_NO'] = v['CONTACT_DETAIL'][0]['RES_PHONE_NO']
                        dataFrm["HOLDER_RECORDS"][k]['CONTACT_DETAIL'][0]['RES_STD'] = v['CONTACT_DETAIL'][0]['RES_STD']
                        
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['ANY_OTH_INFO'] = v['OTHER_DETAIL'][0]['ANY_OTH_INFO']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['GROSS_INCOME'] = v['OTHER_DETAIL'][0]['GROSS_INCOME']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['KRA_ADDR_TYPE'] = v['OTHER_DETAIL'][0]['KRA_ADDR_TYPE']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['NET_DATE'] = v['OTHER_DETAIL'][0]['NET_DATE']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['NET_WORTH'] = v['OTHER_DETAIL'][0]['NET_WORTH']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['OCCUPATION'] = v['OTHER_DETAIL'][0]['OCCUPATION']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['OCCUPATION_OTH'] = v['OTHER_DETAIL'][0]['OCCUPATION_OTH']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['PEP'] = v['OTHER_DETAIL'][0]['PEP']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['SOURCE_OF_WEALTH'] = v['OTHER_DETAIL'][0]['SOURCE_OF_WEALTH']
                        dataFrm["HOLDER_RECORDS"][k]['OTHER_DETAIL'][0]['SOURCE_OF_WEALTH_OTH'] = v['OTHER_DETAIL'][0]['SOURCE_OF_WEALTH_OTH']
                        

                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['BIRTH_CITY'] = v['FATCA_DETAIL'][0]['BIRTH_CITY']
                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['BIRTH_COUNTRY'] = v['FATCA_DETAIL'][0]['BIRTH_COUNTRY']
                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['BIRTH_COUNTRY_OTH'] = v['FATCA_DETAIL'][0]['BIRTH_COUNTRY_OTH']
                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['CITIZENSHIP'] = v['FATCA_DETAIL'][0]['CITIZENSHIP']
                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['CITIZENSHIP_OTH'] = v['FATCA_DETAIL'][0]['CITIZENSHIP_OTH']
                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['NATIONALITY'] = v['FATCA_DETAIL'][0]['NATIONALITY']
                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['NATIONALITY_OTH'] = v['FATCA_DETAIL'][0]['NATIONALITY_OTH']
                        dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAX_RES_FLAG'] = v['FATCA_DETAIL'][0]['TAX_RES_FLAG']

                        if(v['FATCA_DETAIL'][0]['TAX_RES_FLAG'] == "Y") {
                            // dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAXS_RECORDS'] = v['FATCA_DETAIL'][0]['TAX_RES_FLAG']

                            v['FATCA_DETAIL'][0]['TAXS_RECORDS'][0]['TAX_RECORD'].forEach((vi, ki) => {
                                console.log("FATCA_DETAIL", vi)
                                dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAXS_RECORDS'][ki]['SEQ_NUM'] = vi['SEQ_NUM']
                                dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAXS_RECORDS'][ki]['IDENTI_TYPE'] = vi['IDENTI_TYPE']
                                dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAXS_RECORDS'][ki]['IDENTI_TYPE_OTH'] = vi['IDENTI_TYPE_OTH']
                                dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAXS_RECORDS'][ki]['TAX_COUNTRY'] = vi['TAX_COUNTRY']
                                dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAXS_RECORDS'][ki]['TAX_COUNTRY_OTH'] = vi['TAX_COUNTRY_OTH']
                                dataFrm["HOLDER_RECORDS"][k]['FATCA_DETAIL'][0]['TAXS_RECORDS'][ki]['TAX_REF_NO'] = vi['TAX_REF_NO']
                            })
                        }
 

                    })

                    showFullPageLoader.value = false
                }, 2000)

                if(reg_request['BANK_DETAILS'][0]['BANK_RECORD']) {
                    reg_request['BANK_DETAILS'][0]['BANK_RECORD'].forEach(function(v, k){
                        dataFrm['BANK_DETAILS'][k]['ACCOUNT_NO'] = v['ACCOUNT_NO']
                        dataFrm['BANK_DETAILS'][k]['ACCOUNT_TYPE'] = v['ACCOUNT_TYPE']
                        dataFrm['BANK_DETAILS'][k]['BANK_ID'] = v['BANK_ID']
                        dataFrm['BANK_DETAILS'][k]['DEFAULT_ACC_FLAG'] = v['DEFAULT_ACC_FLAG']
                        dataFrm['BANK_DETAILS'][k]['IFSC_CODE'] = v['IFSC_CODE']
                        dataFrm['BANK_DETAILS'][k]['MICR_CODE'] = v['MICR_CODE']
                        dataFrm['BANK_DETAILS'][k]['PROOF'] = v['PROOF']
                        dataFrm['BANK_DETAILS'][k]['SEQ_NUM'] = v['SEQ_NUM']
                    })
                }
                
                dataFrm['NOMINEE_DETAILS'][0]['NOMIN_OPT_FLAG'] = reg_request['NOMINEE_DETAILS'][0]['NOMIN_OPT_FLAG']
                dataFrm['NOMINEE_DETAILS'][0]['NOM_VERIFY_TYPE'] = reg_request['NOMINEE_DETAILS'][0]['NOM_VERIFY_TYPE']


                if(reg_request['NOMINEE_DETAILS'][0]['NOMIN_OPT_FLAG'] == "Y") {
                    reg_request['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][0]['NOMINEE_RECORD'].forEach(function(v, k){ 
                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['DOB'] = v['DOB']
                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['NOMINEE_NAME'] = v['NOMINEE_NAME']
                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['NOM_GURI_NAME'] = v['NOM_GURI_NAME']
                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['PERCENTAGE'] = v['PERCENTAGE']
                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['RELATION'] = v['RELATION']
                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['SEQ_NUM'] = v['SEQ_NUM']

                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['NOM_GURI_REL'] = v['NOM_GURI_REL']
                        dataFrm['NOMINEE_DETAILS'][0]['NOMINEES_RECORDS'][k]['NOM_GURI_DOB'] = v['NOM_GURI_DOB']
                    })
                }

                

                if(proof.length > 0) {
                    dataFrm['proofUploadArray'] = proof
                }

                
            })
            .catch(error => {  
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });

                showFullPageLoader.value = false
            })
        }

        

        // onBeforeMount(() => {
        //     console.log("before mount")
        //     // getECanDetail()
        // })

        function deleteProof(p, k) {
            // console.log(p)
            let c = confirm("Are you sure you wish to delete?")
            if(c) {
                if(p.id) {
                    // console.log("delete from db")
                    showFullPageLoader.value = true
                    EventService.getPostData("post", "mfu/delete-ecan-file", {id: p.id})
                    .then(response => {
                        console.log(response.data)    

                        if(response.data.success == 1) {
                            dataFrm.proofUploadArray.splice(k, 1)
                            notify({
                                title: "Success",
                                text: response.data.msg,
                                type: 'success'
                            });
                        } else {
                            notify({
                                title: "Error",
                                text: response.data.msg,
                                type: 'error'
                            });
                        }

                        showFullPageLoader.value = false

                    })
                    .catch(error => {  
                        showFullPageLoader.value = false
                        console.log("This iwas an error!", error)
                        notify({
                            title: "Error",
                            text: error,
                            type: 'error'
                        });
                    })

                } else {
                    // console.log("delete only row")
                    dataFrm.proofUploadArray.splice(k, 1)
                }
            }
        }

         
        onMounted(() => {
            // console.log(" mount")
            // console.log('The initial count is')
            // dataFrm.HOLDER_RECORDS.push(Object.assign({}, holderObj, { HOLDER_TYPE: 'PR', IDX: 0 }))

            let c1 = Object.assign({}, holderObj, { HOLDER_TYPE: 'PR', IDX: 0 })
            let c2 = JSON.parse(JSON.stringify(c1));
            dataFrm.HOLDER_RECORDS.push(c2)
            
            if(route.params.id) {
                getECanDetail()
            }

            // console.log(dataFrm.HOLDER_RECORDS)
        })

        return {
            showFullPageLoader, dataFrm, saveForLater, submit, selectedHolderTab, selectedBankTab, 
            getYearDiff, addMoreProof, v$, countries, banks, getPANDetail, uploadConfig, readImageUrl, deleteProof 
        }
    }

    
}
</script>

<style scoped>
.mandatory {
    color: red;
}

.headings {
    color: #d9a200;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.nav-tabs.customTab {
  border-bottom-color: #959595;
  overflow-x: none;
  flex-wrap: nowrap;
}

.nav-tabs.customTab .nav-item {
  position: relative;
  margin-bottom: 0;
}

.nav-tabs.customTab li:nth-child(1) {
  z-index: 9;
}
.nav-tabs.customTab li:nth-child(2) {
  z-index: 8;
}
.nav-tabs.customTab li:nth-child(3) {
  z-index: 7;
}
.nav-tabs.customTab li:nth-child(4) {
  z-index: 6;
}
.nav-tabs.customTab li:nth-child(5) {
  z-index: 5;
}
.nav-tabs.customTab li:nth-child(6) {
  z-index: 4;
}

.nav-tabs.customTab .nav-item + .nav-item {
  margin-left: -5px;
}

.nav-tabs.customTab .nav-link {
  /* min-width: 200px; */
  text-align: center;
  color: #fff !important;
  font-size: 14px;
  /* font-weight: 700; */
  border: none;
  padding: 14px 10px;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: transparent;
}

.nav-tabs.customTab .nav-link:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: perspective(200px) rotateX(30deg);
  transform-origin: center bottom;
  background-color: #707a89;
  z-index: -1;
  border-radius: 5px 5px 0 0;
  box-shadow: 2px -1px 7px rgba(0, 0, 0, 0.3);
}

.nav-tabs.customTab .nav-link span {
  position: relative;
  padding: 0 20px;
  display: block;
  top: 7px;
}

.nav-tabs.customTab .nav-link.active:after {
  background-color: #27475e;
}
.nav-tabs.customTab .nav-link.active {
  color: #fff !important;
}

.input-code {
    max-width: 54px;
    text-align: center;
}

.errortext {
    color: red;
} 

.error input {
    color: red;
    border: 1px solid red !important;
}

.error select {
    color: red;
    border: 1px solid red !important;
}

select.error {
    color: red;
    border: 1px solid red !important;
}

input.error {
    color: red;
    border: 1px solid red !important;
}

/* .inputerror {
    color: red;
    border: 1px solid red !important;
} */
</style>